import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="create-car"
export default class extends Controller {
  static targets = ['number']

  connect() {
    const number = document.querySelector('input#car_number').value

    this.numberTarget.value = number
  }
}
