import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timer"
export default class extends Controller {
  static values = {
    startTime: Number
  }

  static targets = [
    "beforeItem",
    "afterItem",
    "timer"
  ]

  connect() {
    this.ticker = setInterval(this.#tick.bind(this), 1000);
  }

  disconnect() {
    clearInterval(this.ticker)
  }

  #tick() {
    if (this.startTimeValue <= 0) {
      clearInterval(this.ticker)
      this.beforeItemTarget.classList.add("hidden")
      this.afterItemTarget.classList.remove("hidden")
      return
    }
    this.startTimeValue--
    const minutes = Math.floor(this.startTimeValue / 60);
    const seconds = this.startTimeValue % 60;
    this.timerTarget.innerHTML = this.#prettyTime(minutes, seconds)
  }

  #prettyTime(minutes, seconds) {
    return minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0')
  }
}
