import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="rating"
export default class extends Controller {
  static values = {
    activeSrc: String,
    inactiveSrc: String,
  };

  // Set label image to mini_app/star-inactive.png for labels with a lower value than the current target
  setRating(event) {
    const rating = event.currentTarget.dataset.rating;
    const labelImages = this.element.querySelectorAll("label img");

    labelImages.forEach((labelImage) => {
      labelImage.src =
        labelImage.dataset.rating <= rating ? this.activeSrcValue : this.inactiveSrcValue;
    });
  }
}
