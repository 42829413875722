import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="reload"
export default class extends Controller {
  static values = {
    time: Number,
    url: String
  };

  connect() {
    this.startReloadTimer();
  }

  startReloadTimer() {
    const reloadTime = this.timeValue || 30;

    setInterval(() => {
      Turbo.visit(this.urlValue, { frame: "content" })
    }, reloadTime * 1000);
  }
}
