import { Controller } from '@hotwired/stimulus'
import Toastify from 'toastify-js'

// Connects to data-controller="toast"
export default class extends Controller {
  static values = { text: String, type: String }

  connect() {
    Toastify({
      text: this.textValue,
      duration: 2000,
      gravity: 'bottom',
      className: `toastify-${this.typeValue}`
    }).showToast()
  }
}
