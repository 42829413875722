import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

// Connects to data-controller="form"
export default class extends Controller {
  static debounces = ["submit"];

  connect() {
    useDebounce(this);
  }

  submit(event) {
    const threshold = event.params.threshold;
    const value = event.target.value;

    // Do not submit if value length is less than threshold
    if (threshold && value && value.length < threshold) {
      return;
    }

    this.element.requestSubmit();
  }
}
