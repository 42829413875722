import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ['trigger', 'content']

  connect() {
  }

  toggle() {
    this.contentTarget.classList.toggle('hidden')
  }

  hide(event) {
    const triggerClicked = this.triggerTarget.contains(event.target)

    if (triggerClicked) {
      return
    }

    this.contentTarget.classList.add('hidden')
  }
}
