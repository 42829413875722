import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pin"
export default class extends Controller {
  static values = {
    pinCount: Number
  }

  static targets = [
    "hiddenField"
  ]

  connect() {
    this.#placePins()
    this.pins = this.element.children
  }

  changeFocus(event) {
    if (!event.target.value) return
    const currentPinIndex = this.#findCurrentPinIndex(event.target)
    this.element.children[currentPinIndex + 1]?.focus()
    this.#changeValue()
  }

  keydown(event) {
    if (event.key != "Backspace") return
    const currentPinIndex = this.#findCurrentPinIndex(event.target)
    this.pins[currentPinIndex].value = ""
    this.pins[currentPinIndex - 1]?.focus()
    event.preventDefault();
    this.#changeValue()
  }

  paste(event) {
    let paste = (event.clipboardData || window.clipboardData).getData("text");
    const currentPinIndex = this.#findCurrentPinIndex(event.target)
    for (let i = currentPinIndex, j = 0; i < this.pins.length && j < paste.length; i++, j++) {
      this.pins[i]?.focus()
      this.pins[i].value = paste[j]
    }
    this.#changeValue()
  }

  selectValue(event) {
    event.target.select()
  }

  #findCurrentPinIndex(target) {
    let index = 0
    for (const pin of this.pins) {
      if (pin == target) return index
      index += 1
    }
  }

  #placePins() {
    for (let i = 0; i < this.pinCountValue; i++) {
      this.element.appendChild(this.#createPin())
    }
  }

  #changeValue() {
    this.hiddenFieldTarget.value = ""
    for (let i = 0; i < this.pins.length; i++) {
      this.hiddenFieldTarget.value += this.pins[i].value
    }
  }

  #createPin() {
    const parser = new DOMParser()
    const pinElement = parser.parseFromString(`
      <input
        type="text"
        inputmode="numeric"
        autocomplete="off"
        maxlength="1"
        required
        data-action="pin#changeFocus keydown->pin#keydown paste->pin#paste click->pin#selectValue"
      >
    `, "text/html").body.childNodes[0]
    return pinElement
  }
}
