import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="kkm"
export default class extends Controller {
  static values = {
    paymentCardData: Object,
    checkData: Object,
    printData: Object
  }

  connect() {
    console.log('Connected to controller', this.checkDataValue)

    if (Object.keys(this.checkDataValue).length) {
      RegisterCheck(this.checkDataValue)
    }

    // if (Object.keys(this.printDataValue).length) {
    //   PrintDocument(this.printDataValue)
    // }
  }

  payByPaymentCardAsync() {
    PayByPaymentCardAsync(this.paymentCardDataValue)
  }

  registerCheck(event) {
    RegisterCheck(event.params.checkData)
  }

  openShift() {
    OpenShift()
  }

  closeShift() {
    Settlement()
    CloseShift()
  }

  depositCash() {
    const amount = prompt('Введите сумму для внесения:')

    DepositingCash(parseInt(amount))
  }

  withdrawCash() {
    const amount = prompt('Введите сумму для изъятия:')

    PaymentCash(parseInt(amount))
  }
}
