import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {
  static values = { url: String };

  connect() {
    if (this.urlValue) {
      new TomSelect(this.element, {
        valueField: "id",
        labelField: "name",
        searchField: ["id", "name", "phone_number", "email"],
        load: (query, callback) => {
          const url = `${this.urlValue}?query=` + encodeURIComponent(query);

          fetch(url)
            .then((response) => response.json())
            .then((json) => {
              callback(json);
            })
            .catch(() => {
              callback();
            });
        },
      });
    } else {
      new TomSelect(this.element, {});
    }
  }
}
