import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="suggestions"
export default class extends Controller {
  static targets = ["input"];

  suggest(event) {
    if (this.inputTarget.value.trim().length !== 0) {
      this.inputTarget.value += "\n";
    }

    this.inputTarget.value += event.params.value;
    this.inputTarget.focus();
  }
}
