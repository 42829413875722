import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bottom-modal"
export default class extends Controller {
  static targets = [
    "backdrop",
    "content"
  ]

  connect() {
    this.backdropClasses = ['opacity-100']
    this.contentClasses = ['bottom-0']
    requestAnimationFrame(() => {
      this.backdropTarget.classList.add(...this.backdropClasses)
      this.contentTarget.classList.add(...this.contentClasses)
    })
  }

  closeBottomModal(event) {
    this.#hideModal()
  }

  submitEnd(event) {
    if (event.detail.success) {
      this.#hideModal()
    }
  }

  #hideModal() {
    requestAnimationFrame(() => {
      this.backdropTarget.classList.remove(...this.backdropClasses)
      this.contentTarget.classList.remove(...this.contentClasses)
    })
    this.backdropTarget.addEventListener("transitionend", () => {
      this.element.parentElement.removeAttribute("src")
      this.element.remove()
    });
  }
}
