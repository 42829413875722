import { Application } from "@hotwired/stimulus";
import HwComboboxController from "@josefarias/hotwire_combobox";

const application = Application.start();

application.register("hw-combobox", HwComboboxController);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
