import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="addresses"
export default class extends Controller {
  static targets = ["map", "branch"];

  static values = {
    branches: Array,
  };

  connect() {
    this.initMap();
  }

  async initMap() {
    console.log("initMap");

    await ymaps3.ready;
    const { YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer } = ymaps3;

    const { YMapDefaultMarker } = await ymaps3.import(
      "@yandex/ymaps3-markers@0.0.1",
    );

    if (!this.hasMapTarget || !this.hasBranchesValue) {
      return;
    }

    const center = this.branchesValue[0].xy.reverse();

    this.ymap = new YMap(
      this.mapTarget,
      {
        location: {
          center: center,
          zoom: 12,
        },
      },
      [new YMapDefaultSchemeLayer({}), new YMapDefaultFeaturesLayer({})],
    );

    this.branchesValue.forEach((branch) => {
      const markerOptions = {
        coordinates: branch.xy.reverse(),
        title: branch.name,
        color: "#2B74B7",
      };
      const marker = new YMapDefaultMarker(markerOptions);
      this.ymap.addChild(marker);
    });
  }

  show(event) {
    this.branchTargets.forEach((branch) => {
      branch.classList.remove("border-primary");
      branch.classList.add("border-transparent");
    });

    event.currentTarget.classList.remove("border-transparent");
    event.currentTarget.classList.add("border-primary");

    this.ymap.setLocation({
      center: event.params.coordinates,
      zoom: 14,
    });
  }
}
